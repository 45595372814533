import React, { PureComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Page from '../../components/Page';
import { get } from '../../util/api';
import Folder from './folder';
import SubFolder from './sub_folder';
import Groups from './groups';
import Images from './images';
import VerifyPage from './verify';
import BuildPage from './build';
import SendWhatsapp from './sendWhatsapp';
import BrandPage from './brandPage';
import Settings from './settings';
import ChooseSchema from './choseSchema';
class WHATSAPPSHOP extends PureComponent {
	state = {
		loading: true,
		checkAgent: false,
		haveAScheme: true,
	};
	componentDidMount() {
		get('/shop/schema/list').then((data) => {
			if (data && data.result && data.result.length === 0) this.setState({ haveAScheme: false });
		});
		get('/shop').then((data) => {
			if ((data && data.config && !data.config.ftp_key) || data.success === false) {
				this.setState({ checkAgent: true, loading: false });
			} else {
				this.setState({ loading: false, keyword: data.config.ftp_key, subdomain: data.keyword });
			}
		});
	}
	render() {
		let { loading, keyword, subdomain, haveAScheme } = this.state;
		if (loading) return <Page.Loader></Page.Loader>;
		if (haveAScheme === false) {
			return (
				<Switch>
					<Route path="/whatsapp-shop/modal" exact render={(props) => <ChooseSchema {...props} keyword={keyword} />}></Route>
					<Redirect to="/whatsapp-shop/modal" />
				</Switch>
			);
		} else {
			return (
				<Switch>
					<Route path="/whatsapp-shop/broadcast/:folder" render={(props) => <SendWhatsapp {...props} keyword={keyword} />}></Route>
					<Route path="/whatsapp-shop/build" render={(props) => <BuildPage {...props} keyword={keyword} />}></Route>
					<Route path="/whatsapp-shop/verify/:folder" render={(props) => <VerifyPage {...props} />}></Route>
					<Route path="/whatsapp-shop/:folder/:subfolder/:file" render={(props) => <Images {...props} />}></Route>
					<Route path="/whatsapp-shop/:folder/:subfolder" render={(props) => <Groups {...props} />}></Route>
					<Route path="/whatsapp-shop/:folder" render={(props) => <SubFolder {...props} subdomain={subdomain} />}></Route>
					<Route path="/whatsapp-shop" exact render={(props) => <Folder {...props} keyword={keyword} />}></Route>
					<Route path="/whatsapp-shop/brands" render={(props) => <BrandPage {...props} keyword={keyword} />}></Route>
					<Route path="/whatsapp-shop/settings" render={(props) => <Settings {...props} keyword={keyword} />}></Route>
					{haveAScheme === false && <Redirect to="/whatsapp-shop/modal" />}
					<Redirect to="/whatsapp-shop" />
				</Switch>
			);
		}
	}
}

export default WHATSAPPSHOP;
